import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContentBase } from './Data/ContentProvider';
import { useHistory, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Settings from './Constants';

function Content( { movielinks } ) {

    let { sel } = useParams();

    var [appHeight, setAppHeight] = useState(0)
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        function handleResize() {
            setAppHeight(window.innerHeight)
        }
        window.addEventListener('resize', handleResize)
        ReactGA.initialize(Settings.TRACKING_ID);
        return () => {window.removeEventListener('resize', handleResize)}
    }, [])

    useEffect(() => {
        setSelected(sel)
    }, [sel])

    const history = useHistory();

    const gotoMovie = (name) => {
        if (name) {
//            ReactGA.pageview(`${name}`);

            ReactGA.send({hitType: "pageview", page: `/m/${name}`, title: `${name}` })
            history.push(`/m/${name}`)
        }
    }

    return (
        <Container id='Content' style={{ "height": window.innerHeight - 60}}>
            {
                movielinks.filter(f => (selected == null || f.selected == selected)).map((l) => (
                    <Frame key={l.key}>
                        <Title>
                            <Thumbnail key={l.key} src={ContentBase + l.name + '.jpg'} height="300" alt=""
                                onClick={() => gotoMovie(l.name)} />
                            <Caption>{l.title}</Caption>
                        </Title>
                    </Frame>
                ))
            }
        </Container>
    )
}


export default Content

const Container = styled.div`
grid-column: 3 / span 2;
display: flex;
flex-wrap: wrap;
align-content: flex-start;
background-color: black;
overflow-y: scroll;
`
const Frame = styled.div`
display: grid;
background-color: black;
`
const Thumbnail = styled.img`
margin: 10px;
cursor: pointer;
box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
border: 3px solid rgb(192,192,192)  ;
`
const Title = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: flex-start;
align-items: center;
font-size: 16px;
`
const Caption = styled.div`
color: white;
`