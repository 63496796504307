import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import { ContentBase } from './Data/ContentProvider';

function Title( { custStyle, movieLinks } ) {


    let { movie } = useParams();
    const [thumbnailData, setThumbnailData] = useState('');

    useEffect(() => {
        movieLinks.filter(f => f.name == movie).forEach(m => setThumbnailData(m))
    }, [movie,movieLinks])

    return (
        <Contaioner id='Title' style={custStyle}>
        <Thumbnail src={ContentBase + thumbnailData.name + '.jpg'} width="160" alt="" />
        <Caption>
            {thumbnailData.title}
        </Caption>
        </Contaioner>
    )
}

export default Title

const Contaioner = styled.div`
background-color: black;
margin: 10px;
justify-self: start;
display: flex;
flex-flow: column nowrap;
justify-content: flex-start;
`
const Thumbnail = styled.img`
margin: 10px;
box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
border: 3px solid rgb(192,192,192)  ;
`
const Caption = styled.div`
color: #FF1F00;
`
