import './App.css';
import React, { useState, useEffect } from 'react';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline';
import { BrowserRouter as Router, Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import styled from 'styled-components';
import Content from './Content';
import Sidebar from './Sidebar';
import ScratchPad from './Scratcher/ScratchPad';
import Title from './Title';
import Settings from './Constants';
import { ContentBase } from './Data/ContentProvider';
// google analytics
import ReactGA from 'react-ga4';
import statics from './AppStatics';
import parse from "react-html-parser";

function App() {

  // useState defines a "property" for the object
  // appWidth is the property getter, setAppWidth is the setter
  // 1000 is the default value
  var [appWidth, setAppWidth] = useState(1000)
  var [columnsSetting, setColumnsSetting] = useState(createColumnsSetting())
  const [movieSet, setMovieSet] = useState()
  // [] is an empty array
  const [movieLinks, setMovieLinks] = useState([]);
  const [buttons, setButtons] = useState([]);
  // {} is an object
  const [showAbout, setShowAbout] = useState({"opacity" : "0", "pointer-events" : "none"});
  const [aboutText, setAboutText] = useState("");
  const TRACKING_ID = 'G-VBW40BKVH7';

  // useEffect is a setup hook called after a DOM object is painted
  // return () is a cleanup function called after object is destroyed
  useEffect(() => {
    document.title = Settings.siteTitle
    document.getElementsByTagName('body')[0].style.backgroundColor = 'black'
    // callback function to handle resize
    function handleResize() {
      setAppWidth(
        (window.innerWidth < statics.mobile_width) ?
        window.innerWidth : 
        (window.innerWidth < statics.window_min_width) ?
        statics.window_min_width : window.innerWidth)
      setColumnsSetting(createColumnsSetting())
    }
    window.addEventListener('resize', handleResize)
    fetch(ContentBase + 'MovieList').then (res => res.text())
      .then(text => {
        const s = text.split("\n")
        createMovieSet(s)
        createMovieLinks(s)
        createButtons(s)
        })
    fetch(ContentBase + 'About.txt').then (res => res.text())
        .then(text => {setAboutText(text)})
    // google analytics
    //ReactGA.initialize('UA-212135455-2');
    //ReactGA.pageview('/');
    ReactGA.initialize("G-8R1ZHD36X6");
    ReactGA.send({hitType: "pageview", page: "/", title: "the FINAL TWIST" })

    handleResize();
    return () => {window.removeEventListener('resize', handleResize)}
  },[])

  function createColumnsSetting() {
    var x = appWidth * 0.15
    x = (x < statics.side_bar_min_width) ? statics.side_bar_min_width : x
    return "minmax(" + x + "px, 20%) auto auto"
  }

  function createMovieSet(t) {
    setMovieSet(new Set(
    t.map (m => {
        var a = m.split('|')
        return a[0]}))
    )
  }

  function createMovieLinks(t) {
    var i = 0;
    setMovieLinks(
    t.filter(f => (f != '' && f != null))
    .map (m => {
        var a = m.split('|')
        return {key: i++, name: a[0], title: a[1],
          selected: a[1].replaceAll('The ', '').charAt(0)}
        }
    )
    )
  }

  function createButtons(t) {
    var set = new Set(
    t.filter(f => (f != '' && f != null))
    .map (m => {
      var a = m.split('|')
      return a[1].replaceAll('The ', '').charAt(0)})
    )
    setButtons(Array.from(set))
  }

function CheckMovie() {
  let route =  useRouteMatch()
  let match = route.params.movie
  let movieExist = movieSet && movieSet.has (match)
  return movieExist ? <RenderMovie /> : <Redirect to="/" />
}

function RenderMovie() {
  return (
    appWidth > statics.mobile_width ?
      <ThreeColumnsGrid id='ThreeColumnsGrid' style={{ "grid-template-columns": columnsSetting, "grid-template-rows" : "100%"}}>
       <Sidebar buttons={buttons} />
       <ScratchPad />
       <Title movieLinks={movieLinks}/>
      </ThreeColumnsGrid>
     :
      <ThreeColumnsGrid id='ThreeColumnsGrid-mobile' style={{ "grid-template-columns": "0px auto", "grid-template-rows" : "minmax(600px, 100%) auto"}}>
       <Sidebar buttons={buttons} />
       <ScratchPad />
       <Title  movieLinks={movieLinks} custStyle = {{"grid-column" : "2"}}/>
      </ThreeColumnsGrid>
  )
}

const handleAbout = (m) => {
  var width = appWidth + "px"
  setShowAbout({"opacity" : "1", "pointer-events" : "auto", "width" : width})
}
const handleClick = (m) => {
  var width = appWidth + "px"
  setShowAbout({"opacity" : "0", "pointer-events" : "none", "width" : width})
}

  return (
    <div className="App" style={{"height" : "100vh"}}>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"></link>
      <Router>
        <Container id='AppContainer' style = {{"width" : appWidth}}>
            <Switch>
              <Route path="/m/:movie">
              <CheckMovie />
                <Header id='Header' >
                  <div />
                  {Settings.siteTitle}
                  <Info style={{"opacity" : "0"}}/>
                </Header>
              </Route>
              <Route path="/p/:sel">
                <Header id='Header' >
                  <div />
                  {Settings.siteTitle}
                  <Info 
                    onClick={m => handleAbout(m)}
                  >
                  </Info>
                </Header>
                <ThreeColumnsGrid id='ThreeColumnsGrid' style={{ "grid-template-columns": columnsSetting }}>
                  <Sidebar buttons={buttons} />
                  <Content movielinks={movieLinks}/>
                </ThreeColumnsGrid>
              </Route>
              <Route path="/">
                <Header id='Header' >
                  <div />
                  {Settings.siteTitle}
                  <Info 
                    onClick={m => handleAbout(m)}
                  >
                  </Info>
                </Header>
                <ThreeColumnsGrid id='ThreeColumnsGrid' style={{ "grid-template-columns": columnsSetting, "grid-tempalte-rows" : "100vh"}}>
                  <Sidebar buttons={buttons} />
                  <Content movielinks={movieLinks}/>
                </ThreeColumnsGrid>
              </Route>
            </Switch>
          <Footer id='MessageFooter'>
            <Copyright>
              Website by Fai Lau
            </Copyright>
            {/* <Debug>
              {appWidth}
            </Debug> */}
          </Footer>
        </Container>
      </Router>
        <AboutFrame id='AboutFrame' style={showAbout}  onClick={(m) => handleClick(m)}>
          <AboutText id='AboutText'>
            {parse(aboutText)}
          </AboutText>
        </AboutFrame>
    </div>
  );
}

export default App;

const Container = styled.div`
height: 100%;
background-color: black;
color: black;
display: grid;
grid-template-rows: 50px auto;
`
const ThreeColumnsGrid  = styled.div`
grid-row: 2;
width: inherit;
background-color: black;
color: black;
display: grid;
`
const Header = styled.div`
background-color: black;
grid-column-end: span 4;
display: flex;
justify-content: space-between;
align-items: center;
color: #FF1F00;
font-size: 20px;
font: Verdana;
position: fixed;
width: 100vw;
height: 50px;
`
const Footer = styled.div`
background-color: black;
display: flex;
flex-direction: column;
align-items: flex-start;
height: 25px;
position: fixed;
bottom: 0;
`
const Copyright = styled.div`
margin-left: 10px;
color: grey;
`
const Debug = styled.div`
background-color:yellow;
`
const Info = styled(HelpOutlinedIcon)`
margin-right: 10px;
`
const AboutText = styled.div`
grid-column: 2 / 2;
grid-row: 2 / 2;
padding: 20px;
border-style: ridge;
border-color: white;
color: rgba(255,255,0,0.8);
background: rgba(20,20,20,.9);
`
const AboutFrame = styled.div`
position: absolute;
top: 0;
left: 0;
height: 100vh;
display: grid;
grid-template-columns: minmax(40px,200px) auto minmax(40px,200px);
grid-template-rows minmax(40px,200px) auto minmax(40px,200px);
color: black;
background: rgba(128,128,128,0.2);
transition: opacity 0.3s ease;
`