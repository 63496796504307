import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import WaypointContainer from './WaypointContainer'
import { ContentBase } from '../Data/ContentProvider'

function ScratchPad() {

    let { movie } = useParams();

    var [lastShown, setLastShown] = useState(1);
    var [waypoints, setWaypoints] = useState([]);

    const createObjects = (t) => {
        var l = []
        var i = 1;
        t.map(e => {
            if (e.match(/^\d+:\d+$/)) {
                l.push ( {time : e, items : [], id: i++ })
            }
            else {
                if (e != '')  {l[l.length - 1].items.push(e)}
            }
        });
        return l
    }    

    useEffect (()=>{
        fetch(ContentBase + movie + ".txt")
        .then(res => res.text())
        .then(text => setWaypoints(createObjects(text.split("\n"))))
    },[movie])

    const openNextWaypoint = (k => {
        if (k.hints && k.hints.id == lastShown) {
            setLastShown(lastShown + 1)
        }
    })

    return (
        <Container id='ScratchPad' onClick={k => openNextWaypoint(k)}>
            {waypoints && waypoints.filter(f => f.id > 0 && f.id <= lastShown).map((h) =>
                <WaypointContainer id={h.id} key={h.id} hints={h} last_hint={h.id == waypoints.length ? "LAST_HINE" : ""} />
            )}
        </Container>
    )
}

export default ScratchPad

const Container = styled.div`
grid-column: 2;
hhheight: 400px;
display:flex;
flex-direction: column;
flex-wrap: nowrap;
background-color: orange;
border: 2px solid rgb(64,64,64);
padding: 10px;
margin-bottom: 20px;
background-color: rgb(20,20,20);
overflow-y: scroll;
`
