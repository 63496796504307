import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring'
import {statics, mobile_statics } from '../AppStatics'

function HintPanel({ id, cover_text, hint, last_hint }) {

    const props = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 1000 } })

    const { scroll } = useSpring({
        scroll: 200,
        reset: false,
        reverse: false,
        delay: 200,
    })

    const [indent, setIndent] = useState(0);
    const [scratchPanelWidth, setScratchPanelWidth] = useState(statics.scratch_panel_width);
    const [scratchPanelWidthPx, setScratchPanelWidthPx] = useState(statics.scratch_panel_width_px);
    const [panelFrameWidth, setPanelFramelWidth] = useState(statics.panel_frame_width);

    const canvas = useRef(null)
    const [image, setImage] = useState(null)
    const [mouseDown, setMouseDown] = useState(false)
    const [mouseMoved, setMouseMoved] = useState(false)

    useEffect(() => {
        const paste = new Image();
        paste.src = statics.paste_src
        paste.onload = () => setImage(paste)
        cover_text || setIndent(statics.waypoint_click);

        function handleResize() {
            if (window.innerWidth > statics.mobile_width) {
                setScratchPanelWidth(statics.scratch_panel_width)
                setScratchPanelWidthPx(statics.scratch_panel_width_px)
                setPanelFramelWidth(statics.panel_frame_width)
            } else {
                setScratchPanelWidth(mobile_statics.scratch_panel_width)
                setScratchPanelWidthPx(mobile_statics.scratch_panel_width_px)
                setPanelFramelWidth(mobile_statics.panel_frame_width)
            }
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {window.removeEventListener('resize', handleResize)}
    }, [])

    useEffect(() => {
        if (image && canvas) {
            const ctx = canvas.current.getContext("2d")
            canvas.current.width = scratchPanelWidth
            canvas.current.height = 38
            ctx.drawImage(image, 0, 0, scratchPanelWidth, 38);
            ctx.font = "18px 'Gill Sans'";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "navy";
            cover_text && ctx.fillText(cover_text, 10, 38 / 2);
            ctx.fillStyle = "red";
            last_hint && cover_text && ctx.fillText("The Final Twist", 70, 38 / 2);
            last_hint && !cover_text && ctx.fillText(last_hint, 16, 38 / 2);
        }
    }, [image])

    const handleMouseMove = (m) => {
        if (mouseDown) {
            const ctx = canvas.current.getContext("2d")
            const x = (m.nativeEvent.pageX - canvas.current.offsetLeft)
            const y = (m.nativeEvent.pageY - canvas.current.offsetTop)
            ctx.globalCompositeOperation = 'destination-out';
            ctx.beginPath();
            //        ctx.fillStyle='red';
            ctx.arc(x, y, 10, 0, 2 * Math.PI);
            ctx.fill();
            setMouseMoved(true)
        }
    }
    const handleTouchMove = (m) => {
        if (mouseDown) {
            const ctx = canvas.current.getContext("2d")
            const x = (m.touches[0].pageX - canvas.current.offsetLeft)
            const y = (m.touches[0].pageY - canvas.current.offsetTop)
            ctx.globalCompositeOperation = 'destination-out';
            ctx.beginPath();
            ctx.arc(x, y, 10, 0, 2 * Math.PI);
            ctx.fill();
            setMouseMoved(true)
        }
    }
    const handleMouseUp = (m) => {
        setMouseDown(false)
    }
    const handleMouseDown = (m) => {
        setMouseDown(true)
        setMouseMoved(false)
    }
    const handleMouseLeave = (m) => {
        setMouseDown(false)
    }
    const handleMouseClick = (m) => {
        if (mouseMoved) {
            setMouseMoved(false)
            m.stopPropagation()
        }
    }

    return (
        <animated.div style={props} scrollTop={scroll}>
            <Container id={id} style={{ "margin-left": indent, "width": panelFrameWidth}}>
                <TimeStamp>{cover_text}</TimeStamp>
                <Text> {hint}</Text>
                <Canvas id={id} ref={canvas}
                    style={{ "width": scratchPanelWidthPx}}
                    onMouseMove={m => handleMouseMove(m)}
                    onTouchMove={m => handleTouchMove(m)}
                    onMouseUp={m => handleMouseUp(m)}
                    onTouchEnd={m => handleMouseUp(m)}
                    onMouseDown={m => handleMouseDown(m)}
                    onTouchStart={m => handleMouseDown(m)}
                    onMouseLeave={m => handleMouseLeave(m)}
                    onTouchCancel={m => handleMouseLeave(m)}
                    onClick={m => handleMouseClick(m)}
                >
                </Canvas>
            </Container>
        </animated.div>
    )
}


export default HintPanel

const Container = styled.div`
background-color: rgb(230,230,230);
height: 41px;
display: flex;
align-items: center;
border: 1px solid rgb(64,64,64);
color: red;
cursor: pointer;
`
const TimeStamp = styled.div`
padding-left: 10px;
font-size: 15px;
`
const Text = styled.div`
padding-left: 10px;
font-size: 16px;
text-align: left;
line-height: 1.0;
`
const Canvas = styled.canvas`
height: 38px;
position: absolute;
`

