import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';

function Sidebar( { buttons, width } ) {

    let { sel } = useParams();

    // const buttons = (() => {
    //     var set = new Set()
    //     ContentLinks.forEach(m => {
    //         set.add (m.title.replaceAll('The ', '').charAt(0))})
    //     var array = Array.from(set)
    //     return array
    // }) ()

    const history = useHistory();

    const handleClick = (e) => {
        const selected = e.target.innerHTML
        const deselect = selected == 'ALL' ? true : false
        selected == 'ALL' ?
            history.push('/') :
            history.push('/p/' + e.target.innerHTML)
    }

    return (
        <Container id='Sidebar'>
            <AllButton id='AllButton' >
                <Button key='0' className="btn btn-outline-light btn-lg" onClick={k => handleClick(k)}>
                    ALL
                </Button>
            </AllButton>
            <SelectButtons id='SelectButtons' >
                {
                    buttons && buttons.map((m) => (
                        <Button key={m} className={(sel == undefined || m == sel) ? "btn btn-outline-light btn-lg" : "btn btn-outline-secondary btn-lg"}
                            data-bs-toggle="button" autocomplete="off" aria-pressed="true"
                            onClick={k => handleClick(k)}>
                            {m}
                        </Button>
                    )
                    )
                }
            </SelectButtons>
        </Container>
    )
}

export default Sidebar

const Container = styled.div`
grid-column: 1;
background-color: black;
display: grid;
grid-template-rows: 80px auto;
justify-items: start;
`
const AllButton = styled.div`
grid-row:1;
padding: 20px;
background-color: black;
`
const SelectButtons = styled.div`
grid-row: 2;
padding-left: 20px;
padding-right: 20px;
background-color: black;
height: 100 vh;
display: flex;
flex-wrap: wrap;
align-content: flex-start;
`
const Button = styled.div`
margin: 5px;
data-toggle: button;
aria-pressed: false;
autocomplete: off
`
