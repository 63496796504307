import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import HintPanel from './HintPanel'
import statics from '../AppStatics'

function WaypointContainer({ id, hints, last_hint }) {

    var [hintArray, setHintArray] = useState([]);
    var [lastShown, setLastShown] = useState(1);
    var [arraySize, setArraySize] = useState(1);

    useEffect(() => {
        var i = 1;
        var vt = [];
        hints && (vt = hints.items.map((h) => {
            return { "id": i++, "text": h };
        }))
        hints && (vt[0].cover_text = hints.time)
        hints && setHintArray(vt)
        hints && setArraySize(hints.items.length)
    }, [hints])

    const openNextPanel = (k) => {
        if (k.target.id && hintArray[k.target.id - 1].cover_text && k.nativeEvent.offsetX < statics.waypoint_click) {
            k.hints = hints
        }
        else {
            if (k.target.id == lastShown && k.target.id < arraySize) {
                setLastShown(lastShown + 1)
            }
            k.stopPropagation();
        }
    }

    return (
        <Container id='WaypointContainer' onClick={k => openNextPanel(k)}>
            {
                hintArray.filter((h) => h.id > 0 && h.id <= lastShown).map((h) =>
                    <HintPanel key={h.id} id={h.id} hint={h.text} cover_text={h.cover_text}
                        last_hint={(h.id == arraySize || (last_hint != '' && h.id == 1)) ? 'Last Hint' : ''} />)
            }
        </Container>
    )
}

export default WaypointContainer

const Container = styled.div`
    width: 100%;
    grid-column: 2;
    grid-row 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(20,20,20);
    touch-action: none;
`
