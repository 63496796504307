export const statics =
{
    window_min_width: 1035,
    side_bar_min_width: 20,
    mobile_width: 380,
    panel_frame_width: '602px',
    scratch_panel_width_px: '600px',
    scratch_panel_width: 600,
    waypoint_click: 35,
    paste_src: "http://pflau.com/public/visihint/scratch_off_paste.jpg"
}

export const mobile_statics =
{
    panel_frame_width: '402px',
    scratch_panel_width_px: '400px',
    scratch_panel_width: 400,
}

export default statics;
